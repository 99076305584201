import React, { useState, useEffect } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi"; // Hamburger icon for header
import { HiUserCircle, HiShieldCheck } from "react-icons/hi"; // User and Admin icons
import axios from "axios";
import logo from './../../images/logo3.png'
import { API_BASE_URL } from "../../config";
const Header = ({ toggleSidebar }) => {
  // State to store user data
  const [userData, setUserData] = useState(null);

  // Fetch the user data (e.g., from localStorage or API)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
        if (token) {
          const response = await axios.get(`${API_BASE_URL}/api/auth/me`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUserData(response.data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  // Check if user data is available
  if (!userData) {
    return null; // Or show a loading spinner
  }

  return (
    <header className="bg-gray-200 text-white p-5 flex justify-between items-center shadow-lg">
      {/* Hamburger menu for small screens */}
      <button
        className="md:hidden text-2xl" // Only visible on mobile (hidden on larger screens)
        onClick={toggleSidebar}
      >
        <HiOutlineMenuAlt2 />
      </button>

      {/* Logo */}
      <div className="flex items-center space-x-4">
        <img
          src={logo} // Update with the actual path of your logo
          alt="Logo"
          className="h-12" // Adjust the size as per your requirement
        />
      </div>

      {/* User info section */}
      <div className="flex items-center space-x-4">
        {/* User Icon based on role */}
        {userData.role === "admin" ? (
          <HiShieldCheck className="text-yellow-500 text-3xl" /> // Admin icon
        ) : (
          <HiUserCircle className="text-gray-200 text-3xl" /> // User icon
        )}
        
        {/* User's Full Name and Role */}
        <div className="flex flex-col items-end">
        <span className="text-sm font-semibold text-black">{userData.fullName}</span>
<span className="text-xs text-black">{userData.role}</span>

           {/* <span className="text-xs text-gray-400">{userData.email}</span>  */}
        </div>
      </div>
    </header>
  );
};

export default Header;
