import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const QuotationsChart = ({ data, maxUsers = 10 }) => {
  // Safety check to ensure data is valid
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  // Helper function to process the data into the chart format
  const processChartData = (data) => {
    // Ensure that we have valid data for the labels (dates, weeks, or months)
    const labels = Object.keys(data);
    if (labels.length === 0) {
      return { labels: [], datasets: [] };
    }

    // Get top N users based on the number of quotations
    const userQuotations = {};
    const allUserNames = Object.keys(data[labels[0]]);
    
    allUserNames.forEach(userName => {
      let totalQuotations = 0;
      labels.forEach(label => {
        totalQuotations += data[label][userName] || 0;
      });
      if (totalQuotations > 0) { // Exclude users with zero quotations
        userQuotations[userName] = totalQuotations;
      }
    });

    // Sort users by total quotations and pick top N
    const sortedUsers = Object.keys(userQuotations)
      .sort((a, b) => userQuotations[b] - userQuotations[a])
      .slice(0, maxUsers);

    // Beautiful color array
    const colors = [
      'rgba(255, 99, 132, 0.6)',  // Red
      'rgba(54, 162, 235, 0.6)',  // Blue
      'rgba(75, 192, 192, 0.6)',  // Green
      'rgba(153, 102, 255, 0.6)', // Purple
      'rgba(255, 159, 64, 0.6)',  // Orange
      'rgba(255, 205, 86, 0.6)',  // Yellow
      'rgba(255, 99, 71, 0.6)',   // Tomato
      'rgba(255, 182, 193, 0.6)', // Light pink
      'rgba(173, 216, 230, 0.6)', // Light blue
      'rgba(144, 238, 144, 0.6)'  // Light green
    ];

    // Create the datasets for the top N users
    const datasets = sortedUsers.map((userName, index) => {
      const userData = labels.map(label => data[label][userName] || 0);
      return {
        label: userName,
        data: userData,
        backgroundColor: colors[index % colors.length], // Cycle through the color array
        borderColor: colors[index % colors.length].replace('0.6', '1'), // Darker border for contrast
        borderWidth: 1,
      };
    });

    return { labels, datasets };
  };

  const chartData = processChartData(data);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Quotations Generated by User',
        font: {
          size: 20,
        },
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        // Add horizontal scrolling if necessary
        ticks: {
          autoSkip: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        // Adjust if needed, but this ensures proper scale and legibility
      },
    },
  };

  return (
    <div className="w-full max-w-4xl p-6 bg-white rounded-lg shadow-md overflow-x-auto">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default QuotationsChart;
