// // src/App.jsx
// import React, { useEffect, useState } from 'react';
// import Dashboard from './Dashboard';

// const MainDashboard = () => {
//   const [stats, setStats] = useState({ users: 0, clients: 0, quotations: 0 });



//   return (
//     <div className="min-h-screen bg-gray-100">
//       <Dashboard stats={stats} />
//     </div>
//   );
// };

// export default MainDashboard;



import React, { useState,useEffect } from "react";
import Sidebar from "../categories/Sidebar"; // Import Sidebar Component
import Header from "../categories/Header"; // Import Header Component
import Dashboard from './Dashboard';


const MainDashboard = () => {
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);
  const [stats, setStats] = useState({ users: 0, clients: 0, quotations: 0 });
  // Toggle sidebar visibility
  const toggleSidebar = () => setIsSidebarClosed(!isSidebarClosed);
//   useEffect(() => {
//     // Example of how you might fetch data from the API
//     fetch('http://localhost:5000/api/quotations/quotation-stats')
//       .then((response) => response.json())
//       .then((data) => {
//         setStats({
//           users: data.totalUsers || 0,  // Adjust according to your actual API structure
//           clients: data.totalClients || 0,
//           quotations: data.totalQuotations || 0,
//         });
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);
  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-50">
      {/* Sidebar */}
      <Sidebar isSidebarClosed={isSidebarClosed} toggleSidebar={toggleSidebar} />

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <Header toggleSidebar={toggleSidebar} />

        {/* Content Section */}
        <main className="flex-1 p-4 mt:0 md:p-8 overflow-auto">
        <Dashboard  />
        </main>
      </div>
    </div>
  );
};

export default MainDashboard;
