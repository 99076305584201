// src/components/Card.jsx
import React from 'react';

const Card = ({ title, count, icon, color = 'bg-blue-500' }) => {
  return (
    <div className={`p-6 max-w-sm w-full ${color} rounded-lg shadow-md text-white`}>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-semibold">{title}</h3>
          <p className="text-3xl font-bold">{count}</p>
        </div>
        <div className="text-4xl">{icon}</div>
      </div>
    </div>
  );
};

export default Card;
