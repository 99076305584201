import React, { useState, useEffect } from 'react';
import Card from './Card';
import QuotationsChart from './QuotationsChart';
import { FaUsers, FaRegAddressCard, FaClipboardList } from 'react-icons/fa';
import { API_BASE_URL } from "../../config";
const Dashboard = () => {
  const [stats, setStats] = useState({ users: 0, clients: 0, quotations: 0 });
  const [userQuotationsData, setUserQuotationsData] = useState({});
  const [view, setView] = useState('daily'); // Default view is 'daily'

  useEffect(() => {
    // Fetch live data from the API only once
    fetch(`${API_BASE_URL}/api/quotations/quotation-stats`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.quotationStats)) {
          const totalQuotations = data.quotationStats.reduce((acc, item) => acc + item.totalQuotations, 0);
          const totalAmount = data.quotationStats.reduce((acc, item) => acc + item.totalAmount, 0);
          const totalClients = data.totalClients;
          const totalUsers = data.totalUsers;
  
          // Process quotations data
          const processedData = processQuotationsData(data.quotationStats);
  
          const processedStats = { users: totalUsers, clients: totalClients, quotations: totalQuotations };
  
          // Only update if data changes to avoid unnecessary rerenders
          setStats(processedStats);
          setUserQuotationsData(processedData);
        } else {
          console.error("Invalid data format:", data);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []); 

  // Function to process quotations data and group by day, week, and month
  const processQuotationsData = (users) => {
    const daily = {};
    const weekly = {};
    const monthly = {};
  
    // Create a list of all user names
    const allUserNames = users.map(user => user.fullName);
  
    users.forEach(user => {
      const userName = user.fullName;
  
      user.quotations.forEach(quotation => {
        const { createdAt } = quotation;
        const date = new Date(createdAt);
  
        // Use ISO date format to ensure consistency
        const day = date.toISOString().split('T')[0];  // "2024-11-27"
        const week = `${date.getFullYear()}-W${Math.ceil(date.getDate() / 7)}`;
        const month = `${date.getFullYear()}-${date.getMonth() + 1}`;
  
        // Group by Day
        if (!daily[day]) daily[day] = {};
        daily[day][userName] = (daily[day][userName] || 0) + 1;
  
        // Group by Week
        if (!weekly[week]) weekly[week] = {};
        weekly[week][userName] = (weekly[week][userName] || 0) + 1;
  
        // Group by Month
        if (!monthly[month]) monthly[month] = {};
        monthly[month][userName] = (monthly[month][userName] || 0) + 1;
      });
    });
  
    // Ensure that users are present in the daily data even if they didn't have any quotations on that day
    Object.keys(daily).forEach(day => {
      allUserNames.forEach(userName => {
        if (!daily[day][userName]) {
          daily[day][userName] = 0; // Set 0 if no quotations on that day
        }
      });
    });
  
    return { daily, weekly, monthly };
  };
  
  

  // Toggle View Handlers
  const handleToggle = (viewType) => {
    setView(viewType);
  };

  return (
    <div className="flex flex-col items-center p-2 mt-0 space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
        {/* Users Card */}
        <Card
          title="Users"
          count={stats.users}
          icon={<FaUsers />}
          color="bg-green-500"
        />
        
        {/* Clients Card */}
        <Card
          title="Clients"
          count={stats.clients}
          icon={<FaRegAddressCard />}
          color="bg-indigo-500"
        />
        
        {/* Quotations Card */}
        <Card
          title="Quotations"
          count={stats.quotations}
          icon={<FaClipboardList />}
          color="bg-yellow-500"
        />
      </div>

      {/* Toggle Buttons */}
      <div className="flex space-x-4 mb-6">
        <button 
          className={`p-2 rounded-md ${view === 'daily' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`} 
          onClick={() => handleToggle('daily')}
        >
          Day-wise
        </button>
        <button 
          className={`p-2 rounded-md ${view === 'weekly' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`} 
          onClick={() => handleToggle('weekly')}
        >
          Week-wise
        </button>
        <button 
          className={`p-2 rounded-md ${view === 'monthly' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`} 
          onClick={() => handleToggle('monthly')}
        >
          Month-wise
        </button>
      </div>

      {/* Chart for Quotations by User */}
      <QuotationsChart 
        data={userQuotationsData[view]} // Pass the correct data based on the selected view
      />
    </div>
  );
};

export default Dashboard;
