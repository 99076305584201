import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './../../images/logo3.png';

function Login() {
  const navigate = useNavigate();
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/login`, { emailOrUsername, password });

      if (response.data.token) {
        const { role, token } = response.data;
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        if (role === "admin") {
          navigate("/admin-dashboard");
        } else {
          navigate("/user/quotation-create");
        }
        toast.success("Login successful!");
      }
    } catch (err) {
      setError(err.response ? err.response.data.message : "Something went wrong.");
      toast.error("Login failed! Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/forgot-password`, { email: forgotPasswordEmail });
      setForgotPasswordSuccess("Password reset link has been sent to your email.");
      setForgotPasswordError("");
      toast.success("Password reset link sent!");
    } catch (err) {
      setForgotPasswordError(err.response ? err.response.data.message : "Something went wrong.");
      setForgotPasswordSuccess("");
      toast.error("Failed to send reset link! Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-between min-h-screen bg-gradient-to-r from-green-400 to-blue-500  p-6 overflow-hidden">
      <div className="bg-white p-10 rounded-xl shadow-xl w-full max-w-md mx-auto overflow-hidden">
        
        {/* Logo Section */}
        <div className="flex justify-center mb-8">
          <img
            src={logo} // Replace with the actual path to your logo image
            alt="Logo"
            className="h-16" // Adjust height as needed
          />
        </div>

        <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-8">Welcome</h2>
        
        {/* Display error message if login fails */}
        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
        
        {/* Display forgot password success message */}
        {forgotPasswordSuccess && <div className="text-green-500 text-sm mb-4">{forgotPasswordSuccess}</div>}
        
        {/* Display forgot password error message */}
        {forgotPasswordError && <div className="text-red-500 text-sm mb-4">{forgotPasswordError}</div>}

        {!isForgotPassword ? (
          <form onSubmit={handleLogin}>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700" htmlFor="emailOrUsername">
                Email or Username
              </label>
              <input
                type="text"
                id="emailOrUsername"
                name="emailOrUsername"
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                className="mt-2 p-4 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-600 focus:outline-none transition duration-300"
                required
              />
            </div>
            
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-2 p-4 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-600 focus:outline-none transition duration-300"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin border-4 border-t-4 border-indigo-600 border-solid rounded-full w-6 h-6"></div>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </form>
        ) : (
          <form onSubmit={handleForgotPassword}>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700" htmlFor="forgotPasswordEmail">
                Enter your email address
              </label>
              <input
                type="email"
                id="forgotPasswordEmail"
                name="forgotPasswordEmail"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                className="mt-2 p-4 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-600 focus:outline-none transition duration-300"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin border-4 border-t-4 border-indigo-600 border-solid rounded-full w-6 h-6"></div>
                </div>
              ) : (
                "Send Reset Link"
              )}
            </button>
          </form>
        )}

        <p className="mt-4 text-center text-sm text-gray-600">
          {isForgotPassword ? (
            <>
              Remember your password?{" "}
              <a onClick={() => setIsForgotPassword(false)} className="text-indigo-600 cursor-pointer hover:underline">Back to Login</a>
            </>
          ) : (
            <a onClick={() => setIsForgotPassword(true)} className="text-indigo-600 cursor-pointer hover:underline">Forgot Password?</a>
          )}
        </p>
      </div>

      {/* Footer */}
      <footer className="bg-indigo-800 text-white py-4 w-full absolute bottom-0 left-0">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          Designed with ❤️ and crafted with care by <a href="https://www.youtube.com/@SHALENDERSINGH" target="_blank" className="text-indigo-300 hover:text-white">Team SMART ITBOX</a>
        </p>
      </div>
    </footer>

      {/* ToastContainer for displaying toasts */}
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
    </div>
  );
}

export default Login;
