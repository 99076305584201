import React, { useState, useEffect } from 'react';
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi';
import { ToastContainer } from 'react-toastify';
import EditQuotationModal from './EditQuotationModal';
import AddQuotationModal from './AddQuotationModal';
import axios from 'axios';
import { FaFilePdf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../config';
const QuotationTable = () => {
  const [quotations, setQuotations] = useState([]);
  const [filteredQuotations, setFilteredQuotations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [quotationsPerPage] = useState(20);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuotations();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      // Filter quotations based on searchTerm for both clientName and createdBy.fullName
      setFilteredQuotations(
        quotations.filter((quotation) =>
          // Search on quotationIdNumber, clientName, and createdBy.fullName
          String(quotation.quotationIdNumber).toLowerCase().includes(searchTerm.toLowerCase()) ||
          (quotation.client.clientName && quotation.client.clientName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (quotation.createdBy && quotation.createdBy.fullName && quotation.createdBy.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );
    } else {
      setFilteredQuotations(quotations); // Reset when searchTerm is empty
    }
  }, [searchTerm, quotations]);

  const fetchQuotations = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found, please login');
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/api/quotations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedQuotations = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setQuotations(sortedQuotations);
      setFilteredQuotations(sortedQuotations); // Initialize filteredQuotations
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch quotations');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on search change
  };

  const handleAddQuotation = () => {
    setIsAddModalOpen(true);
  };

  const handleEdit = async (quotationId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/quotations/${quotationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const quotation = response.data;
      setSelectedQuotation(quotation);
      setIsEditModalOpen(true);
    } catch (err) {
      console.error('Failed to fetch quotation details', err);
    }
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    
    setSelectedQuotation(null);
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found, please login');
        return;
      }

      await axios.delete(`${API_BASE_URL}/api/quotations/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchQuotations(); // Refresh the quotation list after deletion
    } catch (err) {
      setError('Failed to delete quotation');
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination logic
  const indexOfLastItem = currentPage * quotationsPerPage;
  const indexOfFirstItem = indexOfLastItem - quotationsPerPage;
  const currentQuotations = filteredQuotations.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredQuotations.length / quotationsPerPage);

  // Format date to IST (Indian Standard Time)
  const formatDateToIST = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    };
    return date.toLocaleString('en-IN', options);
  };

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
      <div className="p-4">
        {/* Action section: Create Quotation & Search */}
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <button
            onClick={handleAddQuotation}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-700 w-full sm:w-auto mb-2 sm:mb-0"
          >
            Create Quotation
          </button>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search Quotations..."
            className="p-2 border border-gray-300 rounded-md w-full sm:w-64"
          />
        </div>

        {/* Loading/Error State */}
        {loading ? (
          <div className="text-center py-4">
            <p>Loading quotations...</p>
          </div>
        ) : error ? (
          <div className="text-center py-4 text-red-500">
            <p>{error}</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            {/* Table */}
            <table className="min-w-full table-auto">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Id</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At (IST)</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created By</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client Name</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount(₹)</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PDF</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {/* Loop through the quotation data */}
                {currentQuotations.map((quotation) => (
                  <tr key={quotation._id} className="hover:bg-gray-50">
                    <td className="px-4 py-4 text-sm">{quotation.quotationIdNumber}</td>
                    <td className="px-4 py-4 text-sm">{formatDateToIST(quotation.createdAt)}</td>
                    <td className="px-4 py-4 text-sm">{quotation.createdBy?.fullName || 'Unknown'}</td>
                    <td className="px-4 py-4 text-sm">{quotation.client?.clientName || 'Unknown'}</td>
                    <td className="px-4 py-4  text-sm">{ quotation.totalAfterGST.toLocaleString(2)}</td>
                    <td className="px-4 py-4 text-sm flex space-x-2">
                      {/* Edit Button */}
                      <button
                        onClick={() => handleEdit(quotation._id)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <HiOutlinePencil size={20} />
                      </button>
                      {/* Delete Button */}
                      <button
                        onClick={() => handleDelete(quotation._id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <HiOutlineTrash size={20} />
                      </button>
                    </td>
                    <td className="px-4 py-4  text-sm">
                      <button
                        onClick={() => navigate(`/quotation/${quotation._id}`)} // Use navigate here
                        className="text-red-600 hover:text-red-800" // Red color for PDF
                      >
                        <FaFilePdf size={20} /> {/* PDF Icon */}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination */}
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <div>
            <span className="text-sm text-gray-500">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredQuotations.length)} of {filteredQuotations.length} quotations
            </span>
          </div>

          <div className="flex space-x-2 mt-2 sm:mt-0">
            {/* Previous Page Button */}
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 bg-gray-200 text-gray-600 rounded-lg disabled:bg-gray-400"
            >
              Prev
            </button>
            {/* Next Page Button */}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 bg-gray-200 text-gray-600 rounded-lg disabled:bg-gray-400"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Toast Container for displaying toast messages */}
      <ToastContainer />

      {/* Conditionally render the EditQuotationModal */}
      {isEditModalOpen && selectedQuotation && (
        <EditQuotationModal
          quotation={selectedQuotation}  // Pass the entire quotation object to the modal
          onClose={handleCloseModal}
          onSubmit={fetchQuotations}
        />
      )}

      {/* Conditionally render the AddQuotationModal */}
      {isAddModalOpen && (
        <AddQuotationModal
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={fetchQuotations} // Re-fetch quotations after adding a new quotation
        />
      )}
    </div>
  );
};

export default QuotationTable;
