import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ element, isAdminRoute }) {
  // Check if token exists in localStorage and if the user is authenticated
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');  // Assuming role is stored in localStorage after login

  if (!token) {
    // If no token, redirect to login page
    return <Navigate to="/" replace />;
  }

  if (isAdminRoute && userRole !== 'admin') {
    // If trying to access admin page and the user is not an admin, redirect to login
    return <Navigate to="/" replace />;
  }

  // If everything checks out, render the element (protected route)
  return element;
}

export default PrivateRoute;
