import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import ParentComponent from './components/categories/ParentComponent';
import Login from './components/Authentication/Login';
import UserData from './components/Authentication/UserData';

import ClientPage from './components/clients/ClientPage';

import InvoicePage from './components/QuotationPage/InvoicePage';
import QuotationPage from './components/QuotationPage/QuotationPage';
import UserQuotationPage from './components/User Page/UserQuotationPage';
import ResetPassword from './components/Authentication/ResetPassword';

import PrivateRoute from './PrivateRoute';  // Import the PrivateRoute component
import UserClientPage from './components/User Page/UserClientPage';
import MainDashboard from './components/AdminDashboard/MainDashboard';


function App() {
  return (
    <Router>
      <div className="App">
        {/* Set up routes for different paths */}
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Login />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          
          {/* Protected routes */}
          <Route path="/categories" element={<PrivateRoute element={<ParentComponent />} isAdminRoute={true} />} />
          <Route path="/user-details" element={<PrivateRoute element={<UserData />} isAdminRoute={true} />} />
          <Route path="/client-details" element={<PrivateRoute element={<ClientPage />} isAdminRoute={true} />} />
          <Route path="/quotation-create" element={<PrivateRoute element={<QuotationPage />} isAdminRoute={true} />} />
          <Route path="/quotation/:id" element={<PrivateRoute element={<InvoicePage />} isAdminRoute={false} />} />
          <Route path="/user/quotation-create" element={<PrivateRoute element={<UserQuotationPage />} isAdminRoute={false} />} />
          <Route path="/user/client-create" element={<PrivateRoute element={<UserClientPage />} isAdminRoute={false} />} />
          <Route path="/admin-dashboard" element={<PrivateRoute element={<MainDashboard />} isAdminRoute={true} />} />
          
        </Routes>
      
      </div>
    </Router>
  );
}

export default App;
