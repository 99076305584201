import React, { useEffect, useState, useRef } from "react";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { Printer } from "lucide-react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import ExcelExport from "./ExcelExport";
import logo from "./../../images/logo1.jpeg";
import toWords  from "number-to-words";
import signatoryImage from "./../../images/signatory.jpeg";
export default function InvoicePage() {
  const { id } = useParams();
  const invoiceRef = useRef();
  const [quotation, setQuotation] = useState(null);
  const currentDate = new Date().toLocaleDateString("en-IN", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const currentTime = new Date().toLocaleTimeString("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  const formatCurrencyInWords = (amount) => {
    // Convert the amount to words
    let words = toWords.toWords(amount);
  
    // Capitalize the first letter of each word
    words = words.replace(/\b\w/g, char => char.toUpperCase());
  
    // Replace Western numbering terms with Indian equivalents
    words = words
      .replace(/\bMillion\b/g, 'Lakh')    // 1,000,000 becomes 1 Lakh
      .replace(/\bBillion\b/g, 'Crore')   // 1,000,000,000 becomes 1 Crore
      .replace(/\bTrillion\b/g, 'Arab');  // 1,000,000,000,000 becomes 1 Arab
  
    return `${words} Rupees`;
  };
  const wrapText = (text, maxLength) => {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if (currentLine.length + word.length + 1 > maxLength) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine += currentLine ? " " + word : word;
      }
    });

    if (currentLine) lines.push(currentLine); // Add any remaining text as the last line
    return lines;
  };
  // Fetch quotation data from API
  useEffect(() => {
    const fetchQuotationData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`${API_BASE_URL}/api/quotations/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token if necessary
          },
        });

        if (response.ok) {
          const data = await response.json();
          setQuotation(data);
        } else {
          console.error("Failed to fetch quotation data");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchQuotationData();
  }, []);

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const options = {
      margin: 1,
      filename: `quotation_${quotation?.quotationIdNumber}.pdf`, // Customize filename if needed
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().from(invoiceRef.current).set(options).save(); // Generates the PDF and triggers download
  };
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0"); // Ensure two digits for day
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Get month and ensure two digits (months are 0-indexed)
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  };
  if (!quotation) {
    return <div>Loading...</div>; // Show loading message while data is being fetched
  }
  const totalgstamount = quotation.totalAfterGST - quotation.totalAfterDiscount;

  return (
    <div className="min-h-screen p-8 print:p-0">
      <div
        className="mx-auto max-w-4xl bg-white shadow-md rounded-lg"
        ref={invoiceRef}
      >
        {/* Card Header */}
        <div className="flex flex-row items-center justify-between p-4  relative">
          <div>
            <h1 className="text-3xl font-bold text-primary">Quotation</h1>
          </div>

          {/* Logo in the right corner */}
          <div className="absolute right-4 top-4">
            <img
              src={logo}
              alt="Company Logo"
              className="h-24" // Adjust the size as needed
            />
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <button
              onClick={handlePrint}
              className="flex items-center border border-gray-300 rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 print:hidden"
            >
              <Printer className="mr-2 h-4 w-4" />
              Download Quotation PDF
            </button>
          </div>

          {/* <ExcelExport quotation={quotation} /> */}
        </div>

        {/* Card Content */}
        <div className="space-y-6 p-6">
          {/* Company Header */}
          <div className="flex justify-between">
            <div>
              <h2 className="text-2xl font-bold text-primary">
                Barnala Marketing Agencies
              </h2>
              {/* <p className="text-gray-500">Company slogan</p> */}
            </div>
          </div>

          {/* Invoice Details */}
          <div className="grid grid-cols-2 gap-[15rem]">
            <div className="space-y-1">
              <div className="flex gap-2">
                <span className="font-semibold text-primary">Quotation#:</span>
                <span>{quotation.quotationIdNumber}</span>
              </div>
              <div className="space-y-1">
                <h3 className="font-semibold text-primary">Sender Details:</h3>
                <p>3367 , Ferozepur Road</p>
                <p>Ludhiana</p>
                <p>+917654567865</p>
                <p>India</p>
              </div>
            </div>
            <div className="space-y-">
              <div className="flex gap-">
                <span className="font-semibold text-primary">Date:</span>
                <span>{formatDate(quotation.createdAt)}</span>
              </div>
              <div className="space-y-1">
                <h3 className="font-semibold text-primary">Bill to:</h3>
                <p>{quotation.client.clientName}</p>
                <p>{quotation.client.clientAddress}</p>
                <p>{quotation.client.contactPerson}</p>
              </div>
            </div>
          </div>

          {/* Invoice Items Table */}
          <div className="mt-6">
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr className="border-b">
                  <th className="py-1 text-left font-semibold text-primary">
                    Area
                  </th>
                  <th className="py-1 text-left font-semibold text-primary">
                    Category
                  </th>
                  <th className="py-1 text-left font-semibold text-primary">
                    Item Name
                  </th>
                  <th className="py-1 text-left font-semibold text-primary">
                    Specification
                  </th>
                  <th className="py-1 text-left font-semibold text-primary">
                    Image
                  </th>
                  <th className="py-1 text-right font-semibold text-primary">
                    Quantity
                  </th>
                  <th className="py-1 text-right font-semibold text-primary">
                    Price
                  </th>
                  <th className="py-1 text-right font-semibold text-primary">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {quotation.items.map((item) => (
                  <tr key={item._id} className="border-b">
                    <td className="py-1">{item.area}</td>
                    <td className="py-1">{item.category}</td>
                    <td className="py-1">
                      {item.itemName &&
                        wrapText(item.itemName, 20).map((line, index) => (
                          <div key={index}>{line}</div>
                        ))}
                    </td>
                    <td className="py-2">
                      {item.specification &&
                        wrapText(item.specification, 40).map((line, index) => (
                          <div key={index}>{line}</div>
                        ))}
                    </td>

                    <td className="py-1 text-center">
                      {item.itemImage ? (
                        <img
                          src={item.itemImage}
                          alt={item.itemName}
                          className="w-12 h-12 object-contain border rounded"
                        />
                      ) : (
                        <span className="text-sm text-gray-500">No Image</span>
                      )}
                    </td>
                    <td className="py-1 text-center">{item.quantity}</td>
                    <td className="py-1 text-center">{item.price}</td>
                    <td className="py-1 text-center">{item.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Totals */}
          <div className="mt-4 flex justify-between">
            <div className="space-y-2 w-[40%]">
              {/* <p className="font-semibold text-primary">
    Make all checks payable to
  </p>
  <p>Barnala Marketing Agencies</p> */}
              <br />
              <br />
              <br />
              <br />

              {/* Display Total in Words with some space below */}
              <div className="flex flex-col mt-6">
                {" "}
                {/* This will add the correct gap */}
                <span className="font-semibold text-primary">
                  Total in Words:
                </span>
                <span>{formatCurrencyInWords(quotation.totalAfterGST)}</span>
              </div>
            </div>

            <div className="space-y-2 w-[40%]">
              <div className="flex justify-between gap-6">
                <span className="font-semibold text-primary">Subtotal:</span>
                <span>₹ {quotation.totalBeforeDiscount}</span>
              </div>
              <div className="flex justify-between gap-6">
                <span className="font-semibold text-primary">Discount:</span>
                <span>{quotation.discount}%</span>
              </div>
              <div className="flex justify-between gap-6">
                <span className="font-semibold text-primary">
                  Total After Discount:
                </span>
                <span>₹ {quotation.totalAfterDiscount}</span>
              </div>
              <div className="flex justify-between gap-6">
                <span className="font-semibold text-primary">
                  GST ({quotation.gstPercentage}%)
                </span>
                <span>₹ {totalgstamount.toFixed(2)}</span>
              </div>
              <div className="flex justify-between gap-6 border-t pt-2">
                <span className="text-lg font-bold text-primary">
                  Total (Inc. GST):
                </span>
                <span className="text-lg font-bold">
  ₹ {Math.round(quotation.totalAfterGST).toLocaleString()}
</span>
              </div>
            </div>
          </div>
          {/* Terms & Conditions */}
          <div className="mt-6 flex justify-between">
            {/* Left Side: Terms & Conditions */}
            <div className="w-2/3">
              <h3 className="text-lg font-bold text-primary">
                Terms & Conditions
              </h3>
              <p className="text-sm mt-2">
                Thank you for your business! Please review the terms and
                conditions below.
              </p>
              <ul className="list-disc pl-5 mt-2 text-sm">
                <li>
                  <strong>Payment Terms:</strong> 100% Along with order
                </li>
                <li>
                  <strong>Delivery Period:</strong> 4-8 weeks depending upon the
                  model
                </li>
                <li>
                  <strong>Cartage:</strong> Extra
                </li>
                <li>
                  <strong>Unloading:</strong> Extra
                </li>
                <li>
                  <strong>Validity:</strong> 1 Week
                </li>
                <li>
                  <strong>Warranty period:</strong> 1 Year as per Company Terms
                  & Conditions
                </li>
                <li>
                  <strong>Surplus Material:</strong> All surplus material,
                  consumables & Cartons will remain our property & lifted back
                </li>
              </ul>
            </div>

            {/* Right Side: Authorized Signatory */}
            <div className="w-[30%] text-right mt-12">
              <h4 className="mt-4 flex justify-center font-semibold text-sm">
                For Barnala Marketing Agency
              </h4>

              {/* Image of Authorized Signatory's Signature */}
              <div className="mt-4 flex justify-end">
                {" "}
                {/* This wrapper will ensure the image is aligned under the text */}
                <img
                  src={signatoryImage} // Replace this with the actual path to the image
                  alt="Authorized Signatory's Image"
                  className="w-[18rem] h-28 object-contain" // Set width to 18rem, height adjusts automatically
                />
              </div>
              <h4 className="mt-4 flex justify-center font-semibold text-sm">
            Authorized Signatory
          </h4>
            </div>
          </div>
          
        </div>

        {/* Card Footer */}
        <div className="border-t p-6 text-center text-gray-500">
          <p className="mt-4 text-sm text-gray-500">
            If you have any questions concerning this Quotation,
            <br />
            {quotation?.createdBy?.fullName}, {quotation?.createdBy?.phone},{" "}
            {quotation?.createdBy?.email}
          </p>
          <p>Thank You for Your Business!</p>
        </div>
      </div>

      {/* Global Styles for Print */}
      <style jsx global>{`
        @media print {
          @page {
            margin: 5mm;
          }
          body {
            font-size: 10pt;
            line-height: 1.4;
            print-color-adjust: exact;
          }
          .print\\:hidden {
            display: none !important;
          }
          .table-auto {
            width: 100%;
            border-collapse: collapse;
          }
          .table-auto th,
          .table-auto td {
            border: 1px solid #ccc;
            padding: 4px;
          }
          .table-auto th {
            text-align: left;
          }
          .absolute {
            position: absolute;
          }
          .right-4 {
            right: 1rem;
          }
          .top-4 {
            top: 1rem;
          }
          .print\\:block {
            display: block !important;
          }
          .print\\:hidden {
            display: none !important;
          }
        }
      `}</style>
    </div>
  );
}

// <td className="py-2">

//                      {item.specification.split("\n").map((line, index) => (
//                        <div key={index}>{line}</div>
//                      ))}
//                    </td>

// const downloadPDF = () => {
//   const input = invoiceRef.current;

//   html2canvas(input, {
//     scale: 2, // Optional: increases quality of the rendered canvas
//   }).then((canvas) => {
//     const imgData = canvas.toDataURL("image/png");

//     // Create jsPDF instance and add the canvas image as PDF content
//     const doc = new jsPDF("portrait", "px", "a4");
//     const imgWidth = 400; // A4 width in mm
//     const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjust height to match width
//     doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight); // Add image to PDF
//     doc.save("invoice.pdf"); // Download the PDF
//   });
// };

// const downloadPDF = () => {
//   const input = invoiceRef.current;

//   // Increase canvas quality by adjusting the scale and other parameters
//   html2canvas(input, {
//     scale: 4, // Increase this scale for better quality
//     logging: true, // Optionally log for debugging
//     useCORS: true, // Make sure CORS settings are handled for external images
//     allowTaint: false, // Ensure canvas is clean
//   }).then((canvas) => {
//     const imgData = canvas.toDataURL("image/png");

//     // Create jsPDF instance with better options for A4 size and high quality
//     const doc = new jsPDF("portrait", "mm", "a4");

//     const imgWidth = doc.internal.pageSize.getWidth() - 20; // A4 width minus margin
//     const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

//     // Add the image to the PDF, with proper scaling and positioning
//     doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

//     // Get the PDF as a Blob
//     const pdfBlob = doc.output("blob");

//     // Prepare FormData for uploading to the backend
//     const formData = new FormData();
//     formData.append("quotationPdf", pdfBlob, "invoice.pdf");

//     // Define the API URL to upload the PDF
//     const apiUrl = `http://localhost:5000/api/quotations/${id}/upload-pdf`;
//     const token = localStorage.getItem("token");

//     // Upload the PDF to the server
//     fetch(apiUrl, {
//       method: "PUT",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       body: formData,
//     })
//       .then((response) => {
//         if (response.ok) {
//           console.log("PDF uploaded successfully.");
//         } else {
//           console.error("Failed to upload PDF.");
//         }
//       })
//       .catch((error) => {
//         console.error("Error uploading PDF:", error);
//       });

//     // Save the PDF locally after uploading
//     doc.save("invoice.pdf"); // Trigger the download of the PDF
//   });
// };

// import React, { useRef } from "react";
// import jsPDF from "jspdf";

// const InvoicePage = () => {
//   const invoiceRef = useRef();

//   const downloadPDF = () => {
//     const doc = new jsPDF({
//       orientation: "portrait", // Landscape mode to utilize width
//       unit: "px", // Use pixels for dimensions
//       format: [1152, 1728], // ARCH A dimensions
//     });

//     doc.html(invoiceRef.current, {
//       callback: (doc) => {
//         doc.save("invoice.pdf");
//       },
//       x: 20, // Add some padding on the X-axis
//       y: 20, // Add some padding on the Y-axis
//     });
//   };

//   return (
//     <div>
//       {/* Invoice Content */}
//       <div
//         ref={invoiceRef}
//         style={{
//           padding: "20px",
//           fontFamily: "Arial",
//           width: "1100px", // Adjust the width to fit within ARCH A
//         }}
//       >
//         <h1 style={{ textAlign: "center", color: "#0073e6" }}>SALES INVOICE</h1>
//         {/* Company and Invoice Details */}
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <div>
//             <p><strong>Your Sales Company Name</strong></p>
//             <p>Street Address</p>
//             <p>City, ST ZIP Code</p>
//             <p>Phone: 123-456-7890</p>
//           </div>
//           <div>
//             <p><strong>Date:</strong> 01/01/2024</p>
//             <p><strong>Invoice #:</strong> 123456</p>
//           </div>
//         </div>
//         <hr />
//         {/* Billing and Shipping Info */}
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <div>
//             <p><strong>Bill To:</strong></p>
//             <p>Client Name</p>
//             <p>Client Address</p>
//           </div>
//           <div>
//             <p><strong>Ship To:</strong></p>
//             <p>Client Name</p>
//             <p>Client Address</p>
//           </div>
//         </div>
//         {/* Table Section */}
//         <table
//           style={{
//             width: "100%",
//             borderCollapse: "collapse",
//             marginTop: "20px",
//             fontSize: "14px", // Reduced font size to fit more details
//           }}
//         >
//           <thead>
//             <tr>
//               <th style={styles.th}>SR#</th>
//               <th style={styles.th}>Description</th>
//               <th style={styles.th}>Quantity</th>
//               <th style={styles.th}>Unit Price</th>
//               <th style={styles.th}>Discount</th>
//               <th style={styles.th}>Line Total</th>
//             </tr>
//           </thead>
//           <tbody>
//             {Array.from({ length: 15 }).map((_, index) => (
//               <tr key={index}>
//                 <td style={styles.td}>{index + 1}</td>
//                 <td style={styles.td}>Product Description</td>
//                 <td style={styles.td}>1</td>
//                 <td style={styles.td}>$100.00</td>
//                 <td style={styles.td}>0%</td>
//                 <td style={styles.td}>$100.00</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         {/* Totals Section */}
//         <div style={{ marginTop: "20px", textAlign: "right" }}>
//           <p>Subtotal: $1500.00</p>
//           <p>PST (8%): $120.00</p>
//           <p>GST (6%): $90.00</p>
//           <p><strong>Total Payable: $1710.00</strong></p>
//         </div>
//         <p style={{ marginTop: "20px", fontSize: "12px" }}>
//           Thank you for your business!
//         </p>
//       </div>
//       {/* Download Button */}
//       <button
//         onClick={downloadPDF}
//         style={{
//           marginTop: "20px",
//           padding: "10px 20px",
//           backgroundColor: "#0073e6",
//           color: "#fff",
//           border: "none",
//           cursor: "pointer",
//         }}
//       >
//         Download PDF
//       </button>
//     </div>
//   );
// };

// const styles = {
//   th: {
//     border: "1px solid #ddd",
//     padding: "8px",
//     textAlign: "left",
//     backgroundColor: "#f2f2f2",
//   },
//   td: {
//     border: "1px solid #ddd",
//     padding: "8px",
//     textAlign: "left",
//   },
// };

// export default InvoicePage;
