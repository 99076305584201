import React, { useState } from "react";
import CategoryForm from "./CategoryForm";
import CategoriesTable from "./CategoriesTable";
import Sidebar from "./Sidebar"; // Import Sidebar Component
import Header from "./Header"; // Import Header Component

const ParentComponent = () => {
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => setIsSidebarClosed(!isSidebarClosed);

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar */}
      <Sidebar isSidebarClosed={isSidebarClosed} toggleSidebar={toggleSidebar} />

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <Header toggleSidebar={toggleSidebar} />

        {/* Content Section */}
        <main className="flex-1 p-4 overflow-auto">
          <CategoryForm />
          <CategoriesTable />
        </main>
      </div>
    </div>
  );
};

export default ParentComponent;
