import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import {

  HiOutlineShoppingBag,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineUser,
  HiOutlineX, // Close icon
  HiOutlineDocument,
} from "react-icons/hi";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { toast, ToastContainer } from "react-toastify"; // Importing toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css';

const Sidebar = ({ isSidebarClosed, toggleSidebar }) => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      // Call the logout endpoint (assuming it clears the server-side session or token)
      await axios.post(`${API_BASE_URL}/api/auth/logout`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Send the token to the server to invalidate it
        },
      });
  
      // Clear the token from localStorage (or sessionStorage if you use that)
      localStorage.removeItem("token");
      localStorage.removeItem("role");
  
      // Show success toast
      toast.success("Successfully logged out!", {
      
        autoClose: 3000, 
      });
  
     
      navigate('/')
    } catch (error) {
      console.error("Logout error:", error);
  
      // Show error toast
      toast.error("An error occurred during logout. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000, // Toast will disappear after 3 seconds
      });
    }
  };
  return (
    <>
    
    <div
      className={`${
        isSidebarClosed ? "w-0 md:w-64 hidden md:block" : "w-64"
      } bg-blue-800 text-white h-full p-5 transition-all duration-300 ease-in-out fixed md:relative z-10 overflow-hidden flex flex-col`}
    >
      {/* Header section with toggle button */}
      <div className="flex justify-between items-center">
        {/* Toggle button for small screens */}
        <button
          className="text-white md:hidden"
          onClick={toggleSidebar}
        >
          <span className={`text-2xl ${isSidebarClosed ? "block" : "hidden"}`}>
            &#9776; {/* Hamburger icon */}
          </span>
          <span
            className={`text-xl font-bold ${!isSidebarClosed ? "block" : "hidden"}`}
          >
            <HiOutlineX /> {/* Close icon */}
          </span>
        </button>

        {/* Title for larger screens */}
        <span
          className={`text-lg font-bold ${isSidebarClosed ? "hidden" : "block"}`}
        >
          Admin Dashboard
        </span>
      </div>

      {/* Sidebar Links */}
      <nav className={`mt-10 ${isSidebarClosed ? "hidden" : "block"}`}>
        <ul>
          <li className="mb-4">
            <Link
              to="/admin-dashboard"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
              <HiOutlineHome className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Dashboard</span>
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/categories"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
              <HiOutlineShoppingBag className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Item Master</span>
            </Link>
          </li>
          
          <li className="mb-4">
            <Link
              to="/user-details"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
              <HiOutlineUser className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>User Details</span>
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/client-details"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
              <HiOutlineUser className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Client Details</span>
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/quotation-create"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
              <HiOutlineDocument className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Create Quotation</span>
            </Link>
          </li>
          {/* <li className="mb-4">
            <Link
              to="/settings"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
              <HiOutlineAdjustments className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Settings</span>
            </Link>
          </li> */}
          <li className="mb-4 mt-6">
  <button
    onClick={handleLogout}
    className="flex items-center text-white bg-red-500 hover:bg-red-700 rounded p-2 transition-all w-full"
  >
    <HiOutlineLogout className="inline-block mr-2" />
    <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Logout</span>
  </button>
</li>

        </ul>
      </nav>

      <div className="mt-auto text-center text-sm text-gray-300 py-4">
  <p className="text-sm">
    &copy; {new Date().getFullYear()} Developed by{" "}
    <a
      href="https://www.youtube.com/@SHALENDERSINGH"
      target="_blank"
      rel="noopener noreferrer"
      className="text-yellow-500 hover:text-yellow-700"
    >
      SMART ITBOX
    </a>. All rights reserved.
  </p>
</div>

      
    </div>
    <ToastContainer />
    </>
  );
};

export default Sidebar;





// import React from "react";
// import { Link } from "react-router-dom"; // Import Link from react-router-dom
// import {
//   HiOutlineHome,
//   HiOutlineShoppingBag,
//   HiOutlineAdjustments,
//   HiOutlineUser,
// } from "react-icons/hi";

// const Sidebar = ({ isSidebarClosed, toggleSidebar }) => {
//   return (
//     <div
//       className={`${
//         isSidebarClosed ? "w-16" : "w-64"
//       } bg-indigo-800 text-white h-full p-5 transition-all duration-300 ease-in-out fixed md:relative z-10`}
//     >
//       <div className="flex justify-between items-center">
//         <button className="text-white" onClick={toggleSidebar}>
//           <span className={`text-2xl ${isSidebarClosed ? "block" : "hidden"}`}>
//             &#9776; {/* Hamburger icon */}
//           </span>
//           <span
//             className={`text-lg font-bold ${!isSidebarClosed ? "block" : "hidden"}`}
//           >
//             Admin Dashboard
//           </span>
//         </button>
//       </div>

//       {/* Sidebar Links */}
//       {!isSidebarClosed && (
//         <nav className="mt-10">
//           <ul>
//             <li className="mb-4">
//               <Link
//                 to="/"
//                 className="text-white hover:bg-indigo-600 rounded p-2 block"
//               >
//                 <HiOutlineHome className="inline-block mr-2" />
//                 Dashboard
//               </Link>
//             </li>
//             <li className="mb-4">
//               <Link
//                 to="/categories"
//                 className="text-white hover:bg-indigo-600 rounded p-2 block"
//               >
//                 <HiOutlineShoppingBag className="inline-block mr-2" />
//                 Categories
//               </Link>
//             </li>
//             <li className="mb-4">
//               <Link
//                 to="/settings"
//                 className="text-white hover:bg-indigo-600 rounded p-2 block"
//               >
//                 <HiOutlineAdjustments className="inline-block mr-2" />
//                 Settings
//               </Link>
//             </li>
//             <li className="mb-4">
//               <Link
//                 to="/user-details"
//                 className="text-white hover:bg-indigo-600 rounded p-2 block"
//               >
//                 <HiOutlineUser className="inline-block mr-2" />
//                 User Details
//               </Link>
//             </li>
//             <li className="mb-4">
//               <Link
//                 to="/client-details"
//                 className="text-white hover:bg-indigo-600 rounded p-2 block"
//               >
//                 <HiOutlineUser className="inline-block mr-2" />
//                 Client Details
//               </Link>
//             </li>
//           </ul>
//         </nav>
//       )}
//     </div>
//   );
// };

// export default Sidebar;
