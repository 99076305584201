import React, { useState } from "react";
import axios from "axios";
import { FaPlus, FaTimes, FaSpinner } from "react-icons/fa";
import Modal from "./Modal"; // Import the modal component
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL } from "../../config";
import ExcelUpload from "./ExcelUpload";
const CategoryForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [categories, setCategories] = useState([
    {
      categoryName: "",
      itemName: "",
      specification: "",
      price: "",
      itemImage: null,
    },
  ]);
  const [loading, setLoading] = useState(false);

  // Handle category form changes
  const handleCategoryChange = (e, index) => {
    const { name, value } = e.target;
    const newCategories = [...categories];
    newCategories[index][name] = value;
    setCategories(newCategories);
  };

  // Handle image changes
  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    const newCategories = [...categories];
    newCategories[index].itemImage = file;
    setCategories(newCategories);
  };

  // Add a new category
  const addCategory = () => {
    setCategories([
      ...categories,
      {
        categoryName: "",
        itemName: "",
        specification: "",
        price: "",
        itemImage: null,
      },
    ]);
  };

  // Remove a category
  const removeCategory = (index) => {
    const newCategories = categories.filter((_, i) => i !== index);
    setCategories(newCategories);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCategories([
      {
        categoryName: "",
        itemName: "",
        specification: "",
        price: "",
        itemImage: null,
      },
    ]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    const categoryData = categories.map((category) => ({
      categoryName: category.categoryName,
      itemName: category.itemName,
      specification: category.specification,
      price: category.price,
    }));

    formData.append("categories", JSON.stringify(categoryData));

    categories.forEach((category, index) => {
      if (category.itemImage) {
        formData.append(`itemImage_${index}`, category.itemImage); // Dynamic field names
      }
    });

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/api/categories`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Send auth token
          },
        }
      );

      setLoading(false);
      setIsModalOpen(false);
      toast.success("Categories added successfully!", {
        autoClose: 2000,
      });
      window.location.reload();
      // Show success toast
    } catch (error) {
      setLoading(false);

      // Show error toast
      toast.error("Error uploading categories. Please try again.", {
        autoClose: 2000,
      });
      console.error("Error uploading categories:", error);
    }
  };

  // Handle data parsed from the Excel file
  const handleExcelDataParse = (data) => {
    // Map the parsed Excel data to the categories array
    const newCategories = data.map((row) => ({
      categoryName: row["Category Name"], // You can add a column for category name in the Excel if you want
      itemName: row["Item Name"] || "",
      specification: row["Specification"] || "",
      price: row["Price"] || "",
      itemImage: null, // Images cannot be populated from Excel
    }));

    setCategories(newCategories);
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700"
      >
        Add Item
      </button>
  
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <form onSubmit={handleSubmit}>
          <h2 className="text-xl font-semibold mb-4 text-center">
            Create Item
          </h2>
          <ExcelUpload onExcelDataParse={handleExcelDataParse} />
          {/* Category List Container with Scrollable Area */}
          <div className="max-h-[50vh] sm:max-h-96 overflow-y-auto">
            {categories.map((category, index) => (
              <div key={index} className="space-y-6 mb-4">
                {/* 4 Fields in a Row */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-6">
                  {/* Category Name */}
                  <div>
                    <label className="block font-medium">Category Name</label>
                    <input
                    disabled={loading}
                      type="text"
                      name="categoryName"
                      value={category.categoryName}
                      onChange={(e) => handleCategoryChange(e, index)}
                      className="w-full p-2 border border-gray-300 rounded-lg"
                      required
                    />
                  </div>
  
                  {/* Item Name */}
                  <div>
                    <label className="block font-medium">Item Name</label>
                    <input
                    disabled={loading}
                      type="text"
                      name="itemName"
                      value={category.itemName}
                      onChange={(e) => handleCategoryChange(e, index)}
                      className="w-full p-2 border border-gray-300 rounded-lg"
                      required
                    />
                  </div>
  
                  {/* Specification */}
                  <div>
                    <label className="block font-medium">Specification</label>
                    <textarea
                    disabled={loading}
                      name="specification"
                      value={category.specification}
                      onChange={(e) => handleCategoryChange(e, index)}
                      className="w-full p-2 border border-gray-300 rounded-lg"
                      rows="1"
                      
                    />
                  </div>
  
                  {/* Price */}
                  <div>
                    <label className="block font-medium">Price</label>
                    <input
                    disabled={loading}
                      type="number"
                      name="price"
                      value={category.price}
                      onChange={(e) => handleCategoryChange(e, index)}
                      className="w-full p-2 border border-gray-300 rounded-lg"
                      
                    />
                  </div>
                  <div>
                    <label className="block font-medium">Item Image</label>
                    <input
                    disabled={loading}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageChange(e, index)}
                      className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                  </div>
                </div>
  
                {categories.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeCategory(index)}
                    className="text-red-500 hover:text-red-700 text-sm"
                  >
                    Remove Item
                  </button>
                )}
              </div>
            ))}
          </div>
  
          {/* Add Category Button */}
          <button
            type="button"
            onClick={() => {
              // Only allow adding a new category if the first one is not empty
              if (categories[0].categoryName.trim() !== "") {
                addCategory();
              }
            }}
            className="bg-gray-300 text-gray-700 p-2 rounded-lg hover:bg-gray-500 mb-4 w-full sm:w-auto"
          >
            + Add Item
          </button>
  
          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`px-4 py-2 ml-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
              loading ? "cursor-wait" : ""
            }`}
          >
            {loading ? <FaSpinner className="animate-spin" /> : "Submit"}
          </button>
  
          {/* Cancel Button */}
          <button
            type="button"
            onClick={closeModal}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-gray-600 ml-2"
          >
            Cancel
          </button>
        </form>
      </Modal>
  
      <ToastContainer />
    </>
  );
  
};

export default CategoryForm;
