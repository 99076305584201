import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaTimes,FaSpinner } from "react-icons/fa";
import { API_BASE_URL } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EditQuotationModal = ({ quotation, onClose, onSubmit }) => {
  const [clientId, setClientId] = useState(quotation?.clientId || "");
  const [clientList, setClientList] = useState([]);
  const [showClientList, setShowClientList] = useState(false);
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState(quotation?.items || []);
  const [discount, setDiscount] = useState(quotation?.discount || 0);
  const [gstPercentage, setgstPercentage] = useState(
    quotation?.gstPercentage || 0
  );
  const [clientName, setClientName] = useState("");
  const [loading, setLoading] = useState(false); 
  // Fetch all clients on mount
  useEffect(() => {
    const fetchClients = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setClientList(response.data);
        console.log("Fetched Clients:", response.data);

        // Ensure the quotation has client data
        if (quotation?.client?._id) {
          const client = response.data.find(
            (client) => client._id === quotation.client._id
          );
          if (client) {
            setClientId(client._id); // Set the clientId properly
            console.log("Client found:", client.clientName);
          } else {
            console.log("Client not found for ID:", quotation.client._id);
          }
        } else {
          console.log("No client data in quotation");
        }
      } catch (err) {
        console.error("Failed to fetch clients", err);
      }
    };

    fetchClients();
  }, [quotation?.client?._id]); // Dependency array updated to use quotation.client._id

  // Fetch categories with subtypes
  useEffect(() => {
    const fetchCategories = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/categories/categories-with-subtypes`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(response.data.categories);
      } catch (err) {
        console.error("Failed to fetch categories", err);
      }
    };

    fetchCategories();
  }, []);

  // Handle item input changes
  const handleItemChange = (index, event) => {
    const newItems = [...items];
    newItems[index][event.target.name] = event.target.value;
    setItems(newItems);
  };

  const handleClientClick = (id) => {
    const selectedClient = clientList.find((client) => client._id === id);
    setClientId(id);
    setClientName(selectedClient.clientName); // Update the client name when selecting a client
    setShowClientList(false); // Hide the client list once selected
  };

  const handleItemSelect = (index, event) => {
    const selectedCategory = categories.find(
      (c) => c.categoryName === items[index].category
    );
    if (selectedCategory && selectedCategory.subtypes.length > 0) {
      const selectedItem = selectedCategory.subtypes.find(
        (subtype) => subtype.itemName === event.target.value
      );
      if (selectedItem) {
        const newItems = [...items];
        newItems[index] = {
          ...newItems[index],
          itemName: selectedItem.itemName,
          specification: selectedItem.specification,
          itemImage: selectedItem.itemImage,
          price: selectedItem.price,
        };
        setItems(newItems);
      }
    }
  };

  // Remove item from the list
  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1); // Remove the item at the given index

    setItems(newItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Prevent submission if clientId is empty or not selected
    if (!clientId) {
      console.error("Client must be selected before submitting.");
      return;
    }

    // Ensure quotation._id is available and valid before proceeding
    if (!quotation || !quotation._id) {
      console.error("Quotation ID is missing or invalid.");
      return;
    }

    try {
      const token = localStorage.getItem("token");

      // Make sure token is not null or undefined before proceeding
      if (!token) {
        console.error("Authorization token is missing.");
        return;
      }

      // Use the quotation._id dynamically here
      const response = await axios.put(
        `${API_BASE_URL}/api/quotations/${quotation._id}`, // Use quotation._id here
        {
          clientId, // The client ID to associate with the quotation
          items, // The list of items to be updated
          discount, // The discount to be applied
          gstPercentage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token in the Authorization header
            "Content-Type": "application/json", // Specify the request content type
          },
        }
      );

      // Trigger the onSubmit callback (e.g., refetch or update UI)
      onSubmit();

      // Close the modal after submitting
      onClose();
      toast.success("Quotation updated successfully!");
    } catch (err) {
      toast.error("Failed to update quotation!");
    }finally {
      setLoading(false);
    }
  };

  // Calculate total before and after discount
  const calculateTotals = () => {
    const totalBeforeDiscount = items.reduce((total, item) => {
      return (
        total + (parseFloat(item.price) || 0) * (parseInt(item.quantity) || 1)
      );
    }, 0);

    const discountAmount = (parseFloat(discount) || 0) / 100;
    const totalAfterDiscount = totalBeforeDiscount * (1 - discountAmount);

    // Add GST to the total after discount
    const gstAmount =
      ((parseFloat(gstPercentage) || 0) / 100) * totalAfterDiscount;
    const totalAfterGst = totalAfterDiscount + gstAmount;

    return { totalBeforeDiscount, totalAfterDiscount, totalAfterGst };
  };

  // Destructure the total values
  const { totalBeforeDiscount, totalAfterDiscount, totalAfterGst } =
    calculateTotals();

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        area: "",
        category: "",
        itemName: "",
        specification: "",
        itemImage: "",
        price: "",
        quantity: 1,
      },
    ]);
  };
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-10/12 lg:w-7/10 xl:w-7/10">
        <h2 className="text-xl mb-4">Edit Quotation Number #{quotation.quotationIdNumber}</h2>
        <form onSubmit={handleSubmit}>
          {/* Client Selection */}
          <div className="mb-4 relative">
            <label className="block text-sm font-medium text-gray-600">
              Client
            </label>
            <div className="flex items-center">
              <input
                type="text"
                value={
                  clientId
                    ? clientList.find((client) => client._id === clientId)
                        ?.clientName
                    : ""
                }
                readOnly
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Select Client"
              />
              <button
                type="button"
                onClick={() => setShowClientList(!showClientList)} // Toggle client list visibility
                className="ml-2 text-blue-500"
              >
                <FaPlus />
              </button>
            </div>
            {showClientList && (
              <div className="absolute z-10 bg-white border border-gray-300 rounded-md w-full mt-1">
                <ul className="max-h-60 overflow-auto">
                  {clientList
        .filter(client => !client.isDeleted) // Filter out deleted clients
        .map((client) => (
                    <li
                      key={client._id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleClientClick(client._id)}
                    >
                      {client.clientName}, {client.contactPerson}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Item list */}
          <div className="space-y-4">
            <div className="max-h-80 overflow-y-auto">
              {items.map((item, index) => {
                const selectedCategory = categories.find(
                  (c) => c.categoryName === item.category
                );
                const subtypes = selectedCategory
                  ? selectedCategory.subtypes
                  : [];

                  return (
                    <div key={index} className="flex flex-col space-y-2">
                      <label className="block text-sm font-medium text-gray-600">Item {index + 1}</label>
                      <div className="grid grid-cols-9 gap-3"> {/* Adjusted grid for 7 columns */}
                        {/* Area */}
                        <input
                          type="text"
                          name="area"
                          value={item.area}
                          onChange={(e) => handleItemChange(index, e)}
                          className="w-full px-4 py-2 border rounded-md"
                          placeholder="Area"
                          required
                        />
                        {/* Category Dropdown */}
                        <select
                          name="category"
                          value={item.category}
                          onChange={(e) => handleItemChange(index, e)}
                          className="w-full px-4 py-2 border rounded-md"
                        >
                          <option value="">Select Category</option>
                          {categories
                            .filter((cat) => cat.subtypes && cat.subtypes.length > 0)
                            .map((cat, idx) => (
                              <option key={idx} value={cat.categoryName}>
                                {cat.categoryName}
                              </option>
                            ))}
                        </select>
    
                        {/* Item Name Dropdown */}
                        <select
                          name="itemName"
                          value={item.itemName} // Bind value to itemName
                          onChange={(e) => handleItemSelect(index, e)} // Trigger onChange for auto-fill
                          className="w-full px-4 py-2 border rounded-md"
                        >
                          <option value="">Select Item</option>
                          {subtypes.map((subtype, idx) => (
                            <option key={idx} value={subtype.itemName}>
                              {subtype.itemName}
                            </option>
                          ))}
                        </select>
    
                        {/* Specification */}
                        <textarea
                          name="specification"
                          value={item.specification}
                          onChange={(e) => handleItemChange(index, e)}
                          className="w-full px-2 py-2 border rounded-md col-span-2"
                          placeholder="Specification"
                          required
                          rows="1"
                        />
    
                        {/* Item Image URL input or Image Preview */}
                        <div className="flex flex-col justify-center items-start">
                          {!item.itemImage ? (
                            <input
                              type="text"
                              name="itemImage"
                              value={item.itemImage}
                              onChange={(e) => handleItemChange(index, e)}
                              className="w-full px-2 py-2 border rounded-md"
                              placeholder="Item Image URL"
                            />
                          ) : (
                            <div className="mt-2">
                              <img
                                src={item.itemImage}
                                alt="Item Preview"
                                className="w-full h-12 max-w-xs object-cover border rounded-md"
                              />
                            </div>
                          )}
                        </div>
    
                        {/* Price */}
                        <input
                          type="number"
                          name="price"
                          value={item.price}
                          onChange={(e) => handleItemChange(index, e)}
                          className="w-full px-2 py-2 border rounded-md"
                          placeholder="Price"
                          required
                        />
    
                        {/* Quantity */}
                        <input
                          type="number"
                          name="quantity"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(index, e)}
                          className="w-full px-2 py-2 border rounded-md"
                          placeholder="Quantity"
                          required
                        />
    
                        {/* Remove Icon */}
                        <div className="flex items-center ml-2"> {/* Align icon next to the last input */}
        <FaTimes
          onClick={() => handleRemoveItem(index)} // Remove item when clicked
          className="text-red-500 cursor-pointer hover:text-red-700"
          style={{ alignSelf: "center" }} // Centers the icon vertically
        />
      </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>

          <div className="mb-4">
            <button
              type="button"
              onClick={handleAddItem} // Handles adding a new item
              className="px-4 py-2 bg-green-500 text-white rounded-lg"
            >
              <FaPlus className="mr-2" />
              Add Item
            </button>
          </div>

          {/* Discount Input */}
          <div className="mt-4 space-y-2">
            <div className="flex space-x-4">
              <h3>Discount</h3>
              <input
                type="number"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                placeholder="Discount (%)"
                className="w-full px-4 py-2 border rounded-md"
              />
              <h3>GST%</h3>
              <input
                type="number"
                value={gstPercentage}
                onChange={(e) => setgstPercentage(e.target.value)}
                placeholder="GST (%)"
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
          </div>

          {/* Totals */}
          <div className="mb-4">
            <div className="flex justify-between">
              <span>Total Before Discount:</span>
              <span>₹{totalBeforeDiscount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span>Total After Discount:</span>
              <span>₹{totalAfterDiscount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span>Total After GST:</span>
              <span>₹{totalAfterGst.toFixed(2)}</span>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg"
            >
              Cancel
            </button>
            <button
            type="submit"
            disabled={loading}
            className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${loading ? 'cursor-wait' : ''}`}
          >
            {loading ? <FaSpinner className="animate-spin" /> : "Update Quotation"}
          </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditQuotationModal;
