import React, { useState } from "react";
import Sidebar from "./Sidebar"; // Import Sidebar Component
import Header from "./Header"; // Import Header Component
import QuotationTable from "../QuotationPage/QuotationTable";


const UserQuotationPage = () => {
  const [isSidebarClosed, setIsSidebarClosed] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => setIsSidebarClosed(!isSidebarClosed);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-50">
      {/* Sidebar */}
      <Sidebar isSidebarClosed={isSidebarClosed} toggleSidebar={toggleSidebar} />

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <Header toggleSidebar={toggleSidebar} />

        {/* Content Section */}
        <main className="flex-1 p-4 md:p-8 overflow-auto">
          <QuotationTable />
        </main>
      </div>
    </div>
  );
};

export default UserQuotationPage;
