import React from "react";
import { Link } from "react-router-dom"; 
import {
  HiOutlineHome,
  HiOutlineShoppingBag,
  HiOutlineAdjustments,
  HiOutlineUser,
  HiOutlineX, 
  HiOutlineDocument,
  HiOutlineLogout, 
} from "react-icons/hi";
import axios from "axios";
import { API_BASE_URL } from "../../config";

const Sidebar = ({ isSidebarClosed, toggleSidebar }) => {
  const handleLogout = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/auth/logout`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem('role');
      window.location.href = "/"; 
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <div
      className={`${
        isSidebarClosed ? "w-0 md:w-64 hidden md:block" : "w-64"
      } bg-indigo-800 text-white h-full p-5 transition-all duration-300 ease-in-out fixed md:relative z-10 overflow-hidden flex flex-col`}
    >
      {/* Header section with toggle button */}
      <div className="flex justify-between items-center">
        <button
          className="text-white md:hidden"
          onClick={toggleSidebar}
        >
          <span className={`text-2xl ${isSidebarClosed ? "block" : "hidden"}`}>
            &#9776;
          </span>
          <span
            className={`text-xl font-bold ${!isSidebarClosed ? "block" : "hidden"}`}
          >
            <HiOutlineX />
          </span>
        </button>

        <span
          className={`text-lg font-bold ${isSidebarClosed ? "hidden" : "block"}`}
        >
          User Dashboard
        </span>
      </div>

      {/* Sidebar Links */}
      <nav className={`mt-10 ${isSidebarClosed ? "hidden" : "block"} flex-grow`}>
        <ul>
          <li className="mb-4">
            <Link
              to="/user/quotation-create"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
              <HiOutlineDocument className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Create Quotation</span>
            </Link>
          </li>

          <li className="mb-4">
            <Link
              to="/user/client-create"
              className="flex items-center text-white hover:bg-indigo-600 rounded p-2 transition-all"
            >
               <HiOutlineUser className="inline-block mr-2" />
              <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Client</span>
            </Link>
          </li>
          
          <li className="mb-4 mt-6">
  <button
    onClick={handleLogout}
    className="flex items-center text-white bg-red-500 hover:bg-red-700 rounded p-2 transition-all w-full"
  >
    <HiOutlineLogout className="inline-block mr-2" />
    <span className={`${isSidebarClosed ? "hidden" : "block"}`}>Logout</span>
  </button>
</li>

        </ul>
      </nav>

      {/* Footer Section */}
      <div className="mt-auto text-center text-sm text-gray-300 py-4">
  <p className="text-sm">
    &copy; {new Date().getFullYear()} Developed by{" "}
    <a
      href="https://www.youtube.com/@SHALENDERSINGH"
      target="_blank"
      rel="noopener noreferrer"
      className="text-yellow-500 hover:text-yellow-700"
    >
      SMART ITBOX
    </a>. All rights reserved.
  </p>
</div>

    </div>
  );
};

export default Sidebar;
