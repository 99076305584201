import React from 'react';
import * as XLSX from 'xlsx';

// const sampleData = [
//   { 'Category Name': 'Electronics', 'Item Name': 'Laptop', 'Specification': 'Intel i7, 16GB RAM', 'Price': '999' },
//   { 'Category Name': 'Home Appliances', 'Item Name': 'Washing Machine', 'Specification': '7kg, 1200 RPM', 'Price': '499' },
//   { 'Category Name': 'Furniture', 'Item Name': 'Sofa', 'Specification': '3-seater, Leather', 'Price': '299' },
// ];

const ExcelUpload = ({ onExcelDataParse }) => {
  // Handle the uploaded Excel file
  const handleExcelUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const wb = XLSX.read(binaryStr, { type: 'binary' });

      // Get the first sheet name (assuming the sheet contains the data)
      const ws = wb.Sheets[wb.SheetNames[0]];

      // Parse the sheet into JSON
      const data = XLSX.utils.sheet_to_json(ws);

      // Pass the data to the parent component
      onExcelDataParse(data);
    };

    reader.readAsBinaryString(file);
  };

  // Handle the download of the sample data as an Excel file
  const handleDownload = () => {
    // Sample data
    const sampleData = [
      { 'Category Name': 'Electronics', 'Item Name': 'Laptop', 'Specification': 'Intel i7, 16GB RAM', 'Price': '999' },
      { 'Category Name': 'Home Appliances', 'Item Name': 'Washing Machine', 'Specification': '7kg, 1200 RPM', 'Price': '499' },
      { 'Category Name': 'Furniture', 'Item Name': 'Sofa', 'Specification': '3-seater, Leather', 'Price': '299' },
    ];
  
    // Convert the sample data to a sheet
    const ws = XLSX.utils.json_to_sheet(sampleData);
  
    // Customize column widths for better visibility
    const columnWidths = [
      { wpx: 200 }, // 'Category Name' column
      { wpx: 250 }, // 'Item Name' column
      { wpx: 300 }, // 'Specification' column
      { wpx: 150 }, // 'Price' column
    ];
    ws['!cols'] = columnWidths; // Apply column widths
  
    // Apply styles to the header row (make it bold and colorful)
    const headerRange = XLSX.utils.decode_range(ws['!ref']); // Get the range of the sheet
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const headerCell = ws[XLSX.utils.encode_cell({ r: 0, c: col })]; // Access header cell
      if (headerCell) {
        // Apply bold and background color to the header
        headerCell.s = {
          font: { bold: true },
          fill: { fgColor: { rgb: "FF7F50" } }, // Orange color for header background
          alignment: { horizontal: 'center' } // Center align the header text
        };
      }
    }
  
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sample Data'); // Append the sheet to the workbook
  
    // Trigger the download
    XLSX.writeFile(wb, 'sample_data.xlsx');
  };
  

  return (
    <div className="mb-4">
      <label className="block font-medium mb-2">Import Items from Excel</label>
      <input
        type="file"
        accept=".xlsx,.xls"
        onChange={handleExcelUpload}
        className="p-2 border border-gray-300 rounded-lg"
      />
      <button
        onClick={handleDownload}
        className="mt-4 p-2 bg-blue-500 text-white rounded-lg"
      >
        Download Sample Data
      </button>
    </div>
  );
};

export default ExcelUpload;
