import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaTimes, FaSpinner } from "react-icons/fa";
import { API_BASE_URL } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AddQuotationModal = ({ onClose, onSubmit }) => {
  const [clientId, setClientId] = useState("");
  const [clientList, setClientList] = useState([]);
  const [filteredClientList, setFilteredClientList] = useState([]);
  const [showClientList, setShowClientList] = useState(false); // To toggle client list visibility
  const [categories, setCategories] = useState([]); // To store fetched categories
  const [items, setItems] = useState([
    {
      itemName: "",
      price: 0,
      quantity: 1,
      area: "",
      category: "",
      specification: "",
      itemImage: "",
    },
  ]);
  const [discount, setDiscount] = useState(0);
  const [gstPercentage, setgstPercentage] = useState(18);
  const [loading, setLoading] = useState(false); // Loading state
  const [searchQuery, setSearchQuery] = useState("");
  const [clientLoading, setClientLoading] = useState(false); // Client loading state
  const [categoryLoading, setCategoryLoading] = useState(false); // Category loading state
  // Fetch all clients on mount
  useEffect(() => {
    const fetchClients = async () => {
      setClientLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${API_BASE_URL}/api/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClientList(response.data);
        setFilteredClientList(response.data); // Initialize filtered list with all clients
      } catch (err) {
        toast.error("Failed to fetch clients!");
      } finally {
        setClientLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Fetch categories with subtypes
  useEffect(() => {
    const fetchCategories = async () => {
      setCategoryLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/categories/categories-with-subtypes`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(response.data.categories);
      } catch (err) {
        toast.error("Failed to fetch categories!");
      } finally {
        setCategoryLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        itemName: "",
        price: 0,
        quantity: 1,
        area: "",
        category: "",
        specification: "",
        itemImage: "",
      },
    ]);
  };

  const handleItemChange = (index, event) => {
    const newItems = [...items];
    newItems[index][event.target.name] = event.target.value;
    setItems(newItems);
  };

  useEffect(() => {
    const filteredClients = clientList.filter((client) => {
      return (
        client.clientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.contactPerson.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredClientList(filteredClients); // Update the filtered client list
  }, [searchQuery, clientList]);

  const handleClientClick = (id) => {
    setClientId(id);
    setShowClientList(false); // Hide the client list once selected
  };

  const handleItemSelect = (index, event) => {
    const selectedCategory = categories.find(
      (c) => c.categoryName === items[index].category
    );

    // Check if the selected category has subtypes, and only proceed if subtypes exist
    if (selectedCategory && selectedCategory.subtypes.length > 0) {
      const selectedItem = selectedCategory.subtypes.find(
        (subtype) => subtype.itemName === event.target.value
      );
      if (selectedItem) {
        const newItems = [...items];
        newItems[index] = {
          ...newItems[index],
          itemName: selectedItem.itemName,
          specification: selectedItem.specification,
          itemImage: selectedItem.itemImage,
          price: selectedItem.price,
        };
        setItems(newItems); // Update the items array with the selected item details
      }
    } else {
      // Optionally log if there are no subtypes for the selected category
      console.log("No subtypes available for this category");
    }
  };

  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1); // Remove the item at the given index
    setItems(newItems); // Update the state with the new items array
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/quotations`,
        {
          clientId,
          items,
          discount,
          gstPercentage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      onSubmit();
      onClose();
      toast.success("Quotation created successfully!");
    } catch (err) {
      toast.error("Failed to create quotation!");
    } finally {
      setLoading(false);
    }
  };

  // Calculate total before and after discount
  // Calculate total before and after discount including GST
  const calculateTotals = () => {
    const totalBeforeDiscount = items.reduce((total, item) => {
      return (
        total + (parseFloat(item.price) || 0) * (parseInt(item.quantity) || 1)
      );
    }, 0);

    const discountAmount = (parseFloat(discount) || 0) / 100;
    const totalAfterDiscount = totalBeforeDiscount * (1 - discountAmount);

    // Add GST to the total after discount
    const gstAmount =
      ((parseFloat(gstPercentage) || 0) / 100) * totalAfterDiscount;
    const totalAfterGst = totalAfterDiscount + gstAmount;

    return { totalBeforeDiscount, totalAfterDiscount, totalAfterGst };
  };

  // Destructure the total values
  const { totalBeforeDiscount, totalAfterDiscount, totalAfterGst } =
    calculateTotals();
  let totalgstAmount = totalAfterGst - totalAfterDiscount;
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      {" "}
      {/* Increased z-index */}
      <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-10/12 lg:w-7/10 xl:w-7/10">
        {" "}
        {/* Updated width */}
        <h2 className="text-xl mb-4">Create Quotation</h2>
        <form onSubmit={handleSubmit}>
          {/* Client Selection */}
          <div className="mb-4 relative">
  <label className="block text-sm font-medium text-gray-600">
    Client
  </label>
  <div className="flex items-center space-x-2 w-full">
    {/* Client Name Input is always visible */}
    <div className="flex-1 mb-4">
    <label htmlFor="clientName" className="block text-sm font-medium text-gray-700">
        Client Name
      </label>
      <input
        type="text"
        value={
          clientId
            ? clientList.find((client) => client._id === clientId)?.clientName
            : ""
        }
        readOnly
        className="w-full px-4 py-2 border rounded-md bg-gray-200"
        placeholder="Client Name"
      />
    </div>

    {/* Conditionally render other inputs when a client is selected */}
    {clientId && (
      <>
        <div className="flex-1 mb-4">
          <label htmlFor="contactPerson" className="block text-sm font-medium text-gray-700">
            Contact Person
          </label>
          <input
            type="text"
            id="contactPerson"
            value={
              clientId
                ? clientList.find((client) => client._id === clientId)?.contactPerson
                : ""
            }
            readOnly
            className="w-full px-4 py-2 border rounded-md bg-gray-200"
            placeholder="Contact Person"
          />
        </div>

        <div className="flex-1 mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="text"
            id="email"
            value={
              clientId
                ? clientList.find((client) => client._id === clientId)?.email
                : ""
            }
            readOnly
            className="w-full px-4 py-2 border rounded-md bg-gray-200"
            placeholder="Email"
          />
        </div>

        <div className="flex-1 mb-4">
          <label htmlFor="clientAddress" className="block text-sm font-medium text-gray-700">
            Client Address
          </label>
          <input
            type="text"
            id="clientAddress"
            value={
              clientId
                ? clientList.find((client) => client._id === clientId)?.clientAddress
                : ""
            }
            readOnly
            className="w-full px-4 py-2 border rounded-md bg-gray-200"
            placeholder="Client Address"
          />
        </div>

        <div className="flex-1 mb-4">
          <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-700">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobileNumber"
            value={
              clientId
                ? clientList.find((client) => client._id === clientId)?.mobileNumber
                : ""
            }
            readOnly
            className="w-full px-4 py-2 border rounded-md bg-gray-200"
            placeholder="Mobile Number"
          />
        </div>

        <div className="flex-1 mb-4">
          <label htmlFor="gstNumber" className="block text-sm font-medium text-gray-700">
            GST Number
          </label>
          <input
            type="text"
            id="gstNumber"
            value={
              clientId
                ? clientList.find((client) => client._id === clientId)?.gstNumber
                : ""
            }
            readOnly
            className="w-full px-4 py-2 border rounded-md bg-gray-200"
            placeholder="GST Number"
          />
        </div>
      </>
    )}

    {/* Button to show client list */}
    <button
      type="button"
      onClick={() => setShowClientList(!showClientList)} // Toggle client list visibility
      className="ml-2 text-blue-500"
    >
      <FaPlus />
    </button>
  </div>

  {showClientList && (
    <div className="mt-2">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)} // Update search query
        className="w-full px-4 py-2 border rounded-md"
        placeholder="Search by client name or contact person"
      />
    </div>
  )}

  {showClientList && (
    <div className="absolute z-10 bg-white border border-gray-300 rounded-md w-full mt-1">
      <ul className="max-h-60 overflow-auto">
        {filteredClientList.length === 0 ? (
          <li className="px-4 py-2">No clients found</li>
        ) : (
          filteredClientList
            .filter((client) => !client.isDeleted)
            .map((client) => (
              <li
                key={client._id}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleClientClick(client._id)}
              >
                {client.clientName}, {client.contactPerson}, {client.clientAddress}
              </li>
            ))
        )}
      </ul>
    </div>
  )}
</div>


          {/* Item list */}
          <div className="space-y-4">
            <div className="max-h-80 overflow-y-auto">
              {items.map((item, index) => {
                const selectedCategory = categories.find(
                  (c) => c.categoryName === item.category
                );
                const subtypes = selectedCategory
                  ? selectedCategory.subtypes
                  : [];

                return (
                  <div key={index} className="flex flex-col space-y-2">
                    <label className="block text-sm font-medium text-gray-600">
                      Item {index + 1}
                    </label>
                    <div className="grid grid-cols-2 sm:grid-cols-9 gap-3">
                      {/* Area */}
                      <input
                        type="text"
                        name="area"
                        value={item.area}
                        onChange={(e) => handleItemChange(index, e)}
                        className="w-full px-4 py-2 border rounded-md"
                        placeholder="Area"
                        required
                      />
                      {/* Category Dropdown */}
                      <select
                        name="category"
                        value={item.category}
                        onChange={(e) => handleItemChange(index, e)}
                        className="w-full px-4 py-2 border rounded-md"
                      >
                        <option value="">Select Category</option>
                        {categories
                          .filter(
                            (cat) => cat.subtypes && cat.subtypes.length > 0
                          )
                          .map((cat, idx) => (
                            <option key={idx} value={cat.categoryName}>
                              {cat.categoryName}
                            </option>
                          ))}
                      </select>

                      {/* Item Name Dropdown */}
                      <select
                        name="itemName"
                        value={item.itemName} // Bind value to itemName
                        onChange={(e) => handleItemSelect(index, e)} // Trigger onChange for auto-fill
                        className="w-full px-4 py-2 border rounded-md"
                      >
                        <option value="">Select Item</option>
                        {subtypes.map((subtype, idx) => (
                          <option key={idx} value={subtype.itemName}>
                            {subtype.itemName}
                          </option>
                        ))}
                      </select>

                      {/* Specification */}
                      <textarea
                        name="specification"
                        value={item.specification}
                        onChange={(e) => handleItemChange(index, e)}
                        className="w-full px-2 py-2 border rounded-md col-span-2"
                        placeholder="Specification"
                        required
                        rows="1"
                      />

                      {/* Item Image URL input or Image Preview */}
                      <div className="flex flex-col justify-center items-start">
                        {!item.itemImage ? (
                          <input
                            type="text"
                            name="itemImage"
                            value={item.itemImage}
                            onChange={(e) => handleItemChange(index, e)}
                            className="w-full px-2 py-2 border rounded-md"
                            placeholder="Item Image URL"
                          />
                        ) : (
                          <div className="mt-2">
                            <img
                              src={item.itemImage}
                              alt="Item Preview"
                              className="w-full h-12 max-w-xs object-cover border rounded-md"
                            />
                          </div>
                        )}
                      </div>

                      {/* Price */}
                      <input
                        type="number"
                        name="price"
                        value={item.price}
                        onChange={(e) => handleItemChange(index, e)}
                        className="w-full px-2 py-2 border rounded-md"
                        placeholder="Price"
                        required
                      />

                      {/* Quantity */}
                      <input
                        type="number"
                        name="quantity"
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, e)}
                        className="w-full px-2 py-2 border rounded-md"
                        placeholder="Quantity"
                        required
                      />

                      {/* Remove Icon */}
                      <div className="flex items-center ml-2">
                        {" "}
                        {/* Align icon next to the last input */}
                        <FaTimes
                          onClick={() => handleRemoveItem(index)} // Remove item when clicked
                          className="text-red-500 cursor-pointer hover:text-red-700"
                          style={{ alignSelf: "center" }} // Centers the icon vertically
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex justify-start space-x-2 mb-4">
            <button
              type="button"
              onClick={handleAddItem}
              disabled={
                !items.every(
                  (item) => item.itemName && item.price && item.quantity > 0
                )
              }
              className="bg-blue-500 text-white py-2 px-4 rounded-lg"
            >
              Add Item
            </button>
          </div>

          {/* Discount and GST inputs */}
          <div className="mt-4 space-y-2">
            <div className="flex space-x-4">
              <h3>Discount</h3>
              <input
                type="number"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                placeholder="Discount (%)"
                className="w-full px-4 py-2 border rounded-md"
              />
              <h3>GST%</h3>
              <input
                type="number"
                value={gstPercentage}
                onChange={(e) => setgstPercentage(e.target.value)}
                placeholder="GST (%)"
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
          </div>

          {/* Totals */}
          <div className="mb-4">
            <div className="flex justify-between">
              <span>Total Before Discount:</span>
              <span>₹{totalBeforeDiscount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span>Total After Discount:</span>
              <span>₹{totalAfterDiscount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span>GST Amount:</span>
              <span>₹{totalgstAmount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span>Total After GST:</span>
              <span>₹{totalAfterGst.toFixed(2)}</span>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
                loading ? "cursor-wait" : ""
              }`}
            >
              {loading ? (
                <FaSpinner className="animate-spin" />
              ) : (
                "Create Quotation"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddQuotationModal;
